@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,600&display=swap');
@import url('https://use.typekit.net/stc1fan.css');

:root {
  --milan-navy: #003542;
  --milan-blue: #0088ab;
  --milan-blue-light: #e5f3f7;
  --milan-secondary-blue: #90d5d5;
  --milan-turquoise: #00a5b4;
  --milan-light-tur: #e4f4f4;
  --fallback-blue: #4dbbc7;
  --milan-red: #f13a1a;
  --milan-white: #f9fbfb;
  --milan-off-white: #eff7f7;
  --milan-grey: #c2c2c2;
  --milan-light-grey: #efefef;
  --border-radius: 5px;
  --default-transition: all 200ms ease-in-out;
  --tabs-height: 60px;
  --padding-x: 20px;
  --padding-x-sm: 30px;
  --padding-x-lg: 60px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  font-size: 16px;
  color: var(--milan-navy);
}

#quote-app {
  background-color: var(--milan-light-grey);
  overflow: hidden;
}

.fine-print {
  font-size: 12px;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

header {
  min-height: 60px;
}

select:disabled,
input:disabled {
  cursor: not-allowed;
}

select:disabled {
  background-color: var(--milan-light-grey) !important;
  color: var(--milan-grey) !important;
}

header .logo {
  height: 24px;
  max-width: 100%;
}

.lender-toggle-btn,
header button {
  background-color: var(--milan-light-grey);
  height: 35px;
  font-weight: bold;
  border: none;
  transition: var(--default-transition);
}

.toggle-btns {
  color: var(--milan-grey);
  width: 100px;
}

.toggle-btns-wide {
  width: 120px;
}

.toggle-btns.active {
  background-color: var(--milan-light-tur);
  color: var(--milan-navy);
}

.toggle-btns.primary.active {
  background-color: var(--milan-blue-light);
}

.lender-toggle-btn {
  width: 123px;
}

.lender-toggle-btn.active {
  background-color: var(--milan-turquoise) !important;
  color: var(--milan-white) !important;
}

.padding-x {
  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
}

.pointer {
  cursor: pointer;
}

.body-areas-tabs {
  overflow-x: auto;
  position: relative;
  z-index: 1;
}

.body-areas-tabs::before {
  content: '';
  position: absolute;
  width: 100%;
  height: var(--tabs-height);
  right: 0;
  top: 0;
  background-color: var(--milan-light-tur);
  z-index: 0;
}

.body-areas-tabs.primary-bg::before {
  background-color: var(--milan-blue-light);
}

.body-areas-tabs .nav-item {
  z-index: 100;
}

.body-areas-tabs .nav-item .nav-link {
  color: var(--milan-navy);
  width: 114px;
  height: var(--tabs-height);
  text-align: center;
  border: none;
  border-radius: 0;
  padding: 0 13px;
  margin-bottom: 18px;
  background-color: var(--milan-light-tur);
  position: relative;
  font-weight: 600;
}

.body-areas-tabs .nav-item .nav-link.active {
  color: var(--milan-white);
  background-color: var(--milan-turquoise);
  position: relative;
}

.body-areas-tabs.primary-bg .nav-item .nav-link.active {
  background-color: var(--milan-blue);
}

.body-areas-tabs .nav-item:last-child .nav-link::after {
  content: '';
  position: absolute;
  width: var(--padding-x);
  height: var(--tabs-height);
  left: 100%;
  top: 0;
  background-color: var(--milan-light-tur);
  z-index: 0;
}

.body-areas-tabs .nav-item .nav-link.active::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  border-left: 57px solid transparent;
  border-right: 57px solid transparent;
  border-top: 18px solid var(--milan-turquoise);
}

.body-areas-tabs.primary-bg .nav-item .nav-link.active::before {
  border-top: 18px solid var(--milan-blue);
}

ul.body-areas-tabs {
  border-bottom: none;
}

ul .sub-area-item {
  /* min-width: 175px; */
  height: 55px;
  background-color: var(--milan-light-grey);
  color: #a2a2a2;
  display: block;
  text-align: center;
  border: none;
  font-weight: bold;
}

ul .sub-area-item.selected {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
  transition: var(--default-transition);
}

ul .sub-area-item.primary-bg.selected {
  background-color: var(--milan-blue);
}

#selections-section .form-select {
  height: 46px;
  width: 164px;
  border-radius: var(--border-radius);
}

#selections-section h3 {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.card-border {
  border: 1px solid var(--milan-turquoise);
  overflow: hidden;
}

.card-border.primary-border {
  border: 1px solid var(--milan-blue);
}

.option-card.active {
  border-width: 4px;
}

.option-card .output {
  min-height: 80px;
}
.option-card .output > p {
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: 20px;
}

.option-card.disabled {
  border-color: var(--milan-grey);
}

.option-card.disabled .output {
  background-color: var(--milan-light-grey);
}

.option-card.disabled .output p,
.option-card.disabled .output h3,
.option-card.disabled .output h2 {
  color: var(--milan-grey) !important;
}

.option-card h3 {
  font-family: brandon-grotesque, sans-serif;
}

#option-items .container:last-child {
  border-bottom: none !important;
}

/* #option-items .container:nth-of-type(odd) {
  background-color: var(--milan-white);
}

#option-items .container:nth-of-type(even) {
  background-color: var(--milan-light-grey);
} */

.currency-input {
  height: 46px;
  max-width: 180px;
  border: 1px solid var(--milan-navy);
}

/* ******************* Background Color ******************* */
.milan-bg-navy {
  background-color: var(--milan-navy);
}

.milan-bg-primary {
  background-color: var(--milan-blue);
}

.milan-bg-primary-light {
  background-color: var(--milan-blue-light);
}

.milan-bg-secondary {
  background-color: var(--milan-secondary-blue);
}

.milan-bg-red {
  background-color: var(--milan-red);
}

.milan-bg-tur {
  background-color: var(--milan-turquoise);
}

.milan-bg-light-tur {
  background-color: var(--milan-light-tur);
}

.milan-bg-white {
  background-color: var(--milan-white);
}

.clear-option-btn {
  width: 160px !important;
  height: 45px !important;
}

.d-h {
  font-size: 1.5rem;
  font-weight: 700 !important;
  letter-spacing: -1px;
}

.milan-fs-7 {
  font-size: 0.875rem;
}

/* ******************* Buttons ******************* */
/* Milan Default Button */
.milan-btn {
  border: none;
  transition: var(--default-transition);
  border-radius: var(--border-radius);
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  display: inline-block;
  width: auto;
  line-height: 1.875rem;
  cursor: pointer;
  text-decoration: none;
}

.milan-btn:disabled:hover,
.milan-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: var(--milan-light-grey);
  color: var(--milan-grey);
}

/* Milan Primary Button */
.milan-primary-btn {
  background-color: var(--milan-blue);
  color: var(--milan-white);
}

.milan-primary-btn:hover {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

/* Milan Secondary Button */
.milan-secondary-btn {
  background-color: var(--milan-secondary-blue);
  color: var(--milan-navy);
}

.milan-secondary-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-navy);
}

.milan-secondary-btn:disabled {
  background-color: var(--milan-secondary-blue) !important;
  color: var(--milan-navy) !important;
}

/* Milan Navy Button */
.milan-navy-btn {
  background-color: var(--milan-navy);
  color: var(--milan-white);
}

.milan-navy-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-navy);
}

.milan-navy-btn:disabled {
  background-color: var(--milan-navy) !important;
  color: var(--milan-white) !important;
}

.milan-red-btn {
  background-color: var(--milan-red);
  color: var(--milan-white);
}

.milan-red-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-red);
}

.milan-red-btn:disabled {
  background-color: var(--milan-red) !important;
  color: var(--milan-white) !important;
}

/* Milan Grey Button */
.milan-grey-btn {
  background-color: var(--milan-grey);
  color: var(--milan-navy);
}

.milan-grey-btn:hover {
  background-color: rgba(194, 194, 194, 0.5);
  color: var(--milan-navy);
}

/* Milan Fallback Button */
.milan-fallback-btn {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

.milan-fallback-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-turquoise);
}

.quote-action-btn {
  color: var(--milan-navy);
}

.quote-action-btn.active {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

.quote-action-btn.primary-bg.active {
  background-color: var(--milan-blue);
}

/* ******************* Text Colors ******************* */
.milan-text-primary {
  color: var(--milan-blue) !important;
}

.milan-text-secondary {
  color: var(--milan-secondary-blue) !important;
}

.milan-text-navy {
  color: var(--milan-navy) !important;
}

.milan-text-tur {
  color: var(--milan-turquoise) !important;
}

.milan-text-red {
  color: var(--milan-red) !important;
}

.milan-text-white {
  color: var(--milan-white) !important;
}

/* Font Weight */
.fw-500 {
  font-weight: 500 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/* ******************* Custom Checkbox - Specific for Option Tiles ******************* */
.checkbox-container {
  width: 100%;
  font-size: 0.9rem;
}

.checkbox-container .checkbox-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkbox-container .checkbox {
  position: relative;
  background-color: var(--milan-white);
  width: 18px;
  height: 18px;
  border: 1px solid var(--milan-turquoise);
  border-radius: 3px;
}

.checkbox-container .checkbox.checked {
  background-color: var(--milan-turquoise);
}

.checkbox-container .checkbox.checked span {
  position: absolute;
  top: -4px;
  left: 2px;
  display: block;
  color: #fff;
}

/* ******************* Custom Checkbox ******************* */
.custom-checkbox label {
  cursor: pointer;
  display: inline-block;
  padding-left: 28px;
  margin-bottom: 0px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  font-size: 0.9rem;
}

.custom-checkbox label input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.custom-checkbox .checkmark {
  background-color: var(--milan-off-white);
  height: 18px;
  left: 0px;
  position: absolute;
  top: 1px;
  width: 18px;
  border-radius: 3px;
}

.custom-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  border: solid var(--milan-navy);
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 7px;
  top: 4px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 5px;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.print-display {
  display: none;
}

@media (min-width: 576px) {
  header .logo {
    height: 20px;
  }

  .padding-x {
    padding-left: var(--padding-x-sm);
    padding-right: var(--padding-x-sm);
  }

  ul .sub-area-item {
    min-width: 175px;
  }

  .body-areas-tabs .nav-item:last-child .nav-link::after {
    width: var(--padding-x-sm);
  }
}

@media (min-width: 992px) {
  header {
    height: 89px;
  }

  .padding-x {
    padding-left: var(--padding-x-lg);
    padding-right: var(--padding-x-lg);
  }

  .body-areas-tabs .nav-item:last-child .nav-link::after {
    width: var(--padding-x-lg);
  }
}

@media (min-width: 1200px) {
  header .logo {
    height: 24px;
  }

  .body-areas-tabs .nav-item .nav-link {
    width: 134px;
  }

  .body-areas-tabs .nav-item .nav-link.active::before {
    border-left: 67px solid transparent;
    border-right: 67px solid transparent;
  }
}

@media print {
  @page {
    size: landscape;
  }

  #print-container {
    margin-left: 40px;
  }

  .col-print {
    width: 33% !important;
  }

  .print-hide {
    display: none !important;
  }

  .print-display {
    display: block;
  }
}
